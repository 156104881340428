import React from "react";
import ProfilCard from "../../components/profil-card";

const ProfiliKontejneri = () => {
    return (
        <>
            <div className="container mt-5">
                <h1 className="title is-3">Profili za kontejnere</h1>
                <hr/>
            </div>

            <div className="container">
                <ProfilCard title="Profil 1" lPic="kontejner-1-s.jpg" rPic="kontejner-1.jpg"/>
                <ProfilCard title="Profil 2" lPic="kontejner-2-s.jpg" rPic="kontejner-2.jpg"/>
                <ProfilCard title="Profil 3" lPic="kontejner-3-s.jpg" rPic="kontejner-3.jpg"/>
            </div>
        </>
    )
}

export default ProfiliKontejneri
